import { AboutHero } from '@/AboutPages/AboutHero';
import { Article } from '@/Article';
import { Seo } from '@/layout/Seo';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const GavinManning: Page = () => {
    return (
        <Fragment>
            <Seo
                title="Gavin Manning – Integrate Institute"
                description="Meet Gavin Manning doctor of physical therapy in Burnsville Minnesota"
            />
            <AboutHero>
                <StaticImage
                    src="../../../assets/images/about-us/gavin.jpg"
                    alt="dr Gavin Manning"
                    width={504}
                    height={568}
                />
            </AboutHero>
            <Article>
                <h1
                    css={css`
                        margin: 0;
                    `}
                >
                    Gavin Manning
                </h1>
                <h3
                    css={css`
                        margin: 0 0 32px;
                    `}
                >
                    Doctor of Physical Therapy
                </h3>
                <p>
                    Dr. Gavin was born and raised in Litchfield, MN. He played multiple sports
                    throughout high school, but ultimately stuck with hockey. Gavin continued his
                    academic career at the University of Wisconsin-Stout in Menomonie, WI where he
                    obtained a Bachelors degree in Applied science with a concentration in
                    Interdisciplinary Science and a minor in Business.
                </p>

                <p>
                    Dr. Gavin then proceeded to graduate school at the University of Jamestown in
                    Fargo, ND for his Doctorate of Physical Therapy. He learned an evidence
                    based-practice approach and holistic approach towards treating patients at
                    Jamestown. Gavin has experience with a wide variety of approaches to focus on
                    improving movement, maximizing function, decreasing pain, and educating patients
                    to give them the tools they need to continue rehab outside of physical therapy
                    sessions.
                </p>

                <p>
                    As a physical therapist, Dr. Gavin has a passion for working with any type of
                    patient population along with building rapport with each patient to better
                    understand his or her needs. In Gavin’s free time, he loves to go to the gym,
                    being outdoors and staying active, spending time with his wife, Courtney, their
                    cat, family, and friends.
                </p>
            </Article>
        </Fragment>
    );
};

export default GavinManning;
